
import Vue from "vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"

import DateTimePicker from "./DateTimePicker.vue"
import { VueConstructor } from "vue/types/umd"
import Contact from "./Contact.vue"
import UsersAutocompleteMixin from "@/mixins/UsersAutocompleteMixin.vue"
import GenericAutocomplete from "./GenericAutocomplete.vue"

interface ArrayColor {
  [key: string]: string
}

interface OrderBodyType {
  order_template_id?: number
  space_id?: number,
  position_id?: number,
  address?: string,
  started_at?: string,
  finished_at?: string,
  quantity?: number,
  force_all_periods?: boolean,
  free_text?: string,
  template: {
    contract_type: string
  },
  contact_id?: number | null,
  user_id?: number | null,
  source?: string | null,
  schedules?: string | null,
  working_time?: string | null,
  coefficient?: number | null,
  task?: string | null,
  complementary_information?: string | null,
  reason?: string | null,
  proof?: string | null,
  hour_rate?: number | null,
  collective_agreement_pay_scale?: string | null,
  [key: string]: any
}

interface Period {
  id: number,
  started_at: {
    dateObject: Date | null,
    dateString: string,
    timeString: string,
    updated?: boolean
  },
  finished_at: {
    dateObject: Date | null,
    dateString: string,
    timeString: string,
    updated?: boolean
  }
}

interface ContactData {
  id: number,
  first_name: string,
  last_name: string,
  email: string|null,
  phone: string|null,
  client_id: number
}

interface AddressPosition {
  longitude: number,
  latitude: number
}

export default (Vue as VueConstructor<Vue &
  InstanceType<typeof GenericAutocomplete> &
  InstanceType<typeof Contact> &
  InstanceType<typeof UsersAutocompleteMixin>>).extend({
  name: 'OrderDetails',
  mixins: [UsersAutocompleteMixin],
  props: {
    id: {
      type: Number,
      required: true
    },
    isClosed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DateTimePicker,
    Contact,
    GenericAutocomplete
  },
  data: function () {
    return {
    clientName: "",
    positionName: "",
    address: "",
    address_position: null as null | AddressPosition,
    quantity: 0 as number,
    contractType: null as string|null,
    disabled: true,
    disableForm: false,
    colors: {
      pending: "info",
      processing: "success",
      closed: "secondary",
      cancelled: "primary",
    } as ArrayColor,
    dataReference: {} as OrderBodyType,
    updated: false,
    freeText: "",
    hourRate: null as number|null,
    periods: [] as Period[],
    deletePeriodDialog: {} as {[key: string]: boolean},
    canSendPeriods: false,
    forceAllPeriods: false,
    force: false,
    deleteText: '',
    positionId: null as number|null,
    contact: {first_name: '', last_name: ''} as ContactData,
    contactIdSelected: null as number|null,
    showContact: false,
    activePropositions: [
      {value: null, text: this.$vuetify.lang.t('$vuetify.none')},
      {value: "active_proposal", text: this.$vuetify.lang.t('$vuetify.active_proposal')},
      {value: "remote_prospecting", text: this.$vuetify.lang.t('$vuetify.remote_prospecting')},
      {value: "field_prospecting", text: this.$vuetify.lang.t('$vuetify.field_prospecting')},
      {value: "word_of_mouth", text: this.$vuetify.lang.t('$vuetify.word_of_mouth')},
      {value: "web", text: this.$vuetify.lang.t('$vuetify.source_web')},
      {value: "user_requirement", text: this.$vuetify.lang.t('$vuetify.user_requirement')},
      {value: "events", text: this.$vuetify.lang.t('$vuetify.events')}
    ],
    reasonsItems: [
      {value: "replacing_absent_employee", text: this.$vuetify.lang.t('$vuetify.reasons.replacing_absent_employee')},
      {value: "replacement_before_delete_post", text: this.$vuetify.lang.t('$vuetify.reasons.replacement_before_delete_post')},
      {value: "waiting_hire_C.D.I.", text: this.$vuetify.lang.t('$vuetify.reasons.waiting_hire_CDI')},
      {value: "complementary_professional_training", text: this.$vuetify.lang.t('$vuetify.reasons.complementary_professional_training')},
      {value: "facilitating_recruitment_for_difficulties_unemployment", text: this.$vuetify.lang.t('$vuetify.reasons.facilitating_recruitment_for_difficulties_unemployment')},
      {value: "not_employed_replacement", text: this.$vuetify.lang.t('$vuetify.reasons.not_employed_replacement')},
      {value: "temporary_increase_activity", text: this.$vuetify.lang.t('$vuetify.reasons.temporary_increase_activity')},
      {value: "occasional,_specific_and_non-lasting_task", text: this.$vuetify.lang.t('$vuetify.reasons.occasional,_specific_and_non-lasting_task')},
      {value: "exceptional_export_order", text: this.$vuetify.lang.t('$vuetify.reasons.exceptional_export_order')},
      {value: "urgent_work_required_for_safety_reasons", text: this.$vuetify.lang.t('$vuetify.reasons.urgent_work_required_for_safety_reasons')},
      {value: "facilitating_the_hiring_of_the_labour_code", text: this.$vuetify.lang.t('$vuetify.reasons.facilitating_the_hiring_of_the_labour_code')},
      {value: "temporary_vacancy_of_the_Labour_Code", text: this.$vuetify.lang.t('$vuetify.reasons.temporary_vacancy_of_the_Labour_Code')},
      {value: "constant_use", text: this.$vuetify.lang.t('$vuetify.reasons.constant_use')},
      {value: "seasonal_employment", text: this.$vuetify.lang.t('$vuetify.reasons.seasonal_employment')},
      {value: "training_course", text: this.$vuetify.lang.t('$vuetify.reasons.training_course')},
      {value: "CIPI_training_contracts", text: this.$vuetify.lang.t('$vuetify.reasons.CIPI_training_contracts')},
      {value: "CDPI_training_contracts", text: this.$vuetify.lang.t('$vuetify.reasons.CDPI_training_contracts')},
      {value: "professionalisation_contract", text: this.$vuetify.lang.t('$vuetify.reasons.professionalisation_contract')},
      {value: "training_plan", text: this.$vuetify.lang.t('$vuetify.reasons.training_plan')},
      {value: "qualification_contract", text: this.$vuetify.lang.t('$vuetify.reasons.qualification_contract')}
    ],
    source: null as string|null,
    workingTime: null as string|null,
    coefficient: null as number|null,
    complementary_information: null as string|null,
    orderTask: [] as string[],
    schedules: null as string|null,
    proof: null as string|null,
    reason: null as string|null,
    openPanels: [0] as number[],
    collective_agreement_pay_scale: null as string|null,
    }
  },
  computed: {
    positiveNumberRule() {
      return (v: number|null) => {
        if (v === null || v === undefined) return true
        return (v > 0) || this.$vuetify.lang.t('$vuetify.invalid_quantity')
      }
    },
    maxLengthRule(){
      return (v: string) => ((v.length <= 2500)) || this.$vuetify.lang.t('$vuetify.invalid_length')
    },
    floatRule() {
    return (v: number|null) => {
        if (v === null || v === undefined) return true
        return (v >= 0 && v <= 1000) || this.$vuetify.lang.t('$vuetify.invalid_float')
      }
    }
  },
  async mounted() {
    this.getLogs()
    this.initializeDeleteModal()
  },
  methods: {
    initializeDeleteModal() {
      this.deleteText = this.$vuetify.lang.t('$vuetify.order_period_deletion_confirmation_text')
      this.force = false
    },
    closeDeleteModal(index: number){
      this.deletePeriodDialog[index] = false
      this.initializeDeleteModal()
    },
    updateOrder() {
      // Update order
      const body = this.buildUpdateBody()
      if (Object.keys(body).length !== 0) {
        axios
          .put(`/v1/orders`, {
            [this.id]: body
          })
          .then(() => {
            EventBus.$emit('snackbar', {message: this.$vuetify.lang.t('$vuetify.order_successfully_updated')})
            // We re-run the GET request so that the data is up to date
            this.getLogs()
            this.disabled = true
            this.updated = !this.updated
            this.$emit(
              'orderUpdated',
              {
                'updated':this.updated,
                'coefficient': this.coefficient,
                'hourRate': this.hourRate,
                'reason': this.reason,
                'proof': this.proof
              }
            )
          })
          .catch((error: AxiosError) => {
            let typeError = ''
            // default the snackbar to error, overwrite if no errors are found
            let snackbarBody = {axiosError: error} as {[key:string]: any}
            if (error.response!.data[this.id]!.includes("Order quantity exceeded")) {
              this.quantity = +(this.dataReference.quantity as number)
              typeError = 'quantity'
              snackbarBody = {message: this.$vuetify.lang.t('$vuetify.quantity_equal_or_greater_than_total'), "color": "error"}
            } else {
              for (const [key] of Object.entries(this.deletePeriodDialog)) {
                this.deletePeriodDialog[key] = false
              }
            }
            this.$emit('error', typeError)
            EventBus.$emit('snackbar', snackbarBody)
          })
      }
      // Create/Update order periods
      if (this.canSendPeriods) {
        this.savePeriods()
      }
      // Display no modification detected if needed
      if ((Object.keys(body).length === 0) && !this.canSendPeriods) {

        EventBus.$emit('snackbar', {"message": this.$vuetify.lang.t('$vuetify.no_modification_detected'), "color": "error"})
      }
      this.disabled = true
    },
    async getLogs() {
      axios
        .get(`/v1/orders/${this.id}`)
        .then((response: AxiosResponse) => {
          this.dataReference = response.data

          this.clientName = response.data.client_name
          this.positionName = response.data.position_name
          this.positionId = response.data.position_id

          this.address = response.data.address
          this.address_position = response.data.address_position
          this.quantity = response.data.quantity

          this.freeText = response.data.free_text ?? ""
          this.contractType = response.data.template.contract_type
          this.forceAllPeriods = response.data.force_all_periods
          this.contact = {
            id: response.data.contact_id,
            first_name: response.data.contact_first_name,
            last_name: response.data.contact_last_name,
            email: response.data.contact_email,
            phone: response.data.contact_phone,
            client_id: response.data.client_id
          }
          this.showContact = true
          this.selectedUser = response.data.user_id
          this.source = response.data.source
          this.schedules = response.data.schedules
          this.workingTime = response.data.working_time
          this.coefficient = response.data.coefficient
          this.complementary_information = response.data.complementary_information
          this.orderTask = response.data.task ? response.data.task.split('\n') : []
          this.reason = response.data.reason
          this.proof = response.data.proof
          this.hourRate = response.data.hour_rate
          this.collective_agreement_pay_scale = response.data.collective_agreement_pay_scale
          // We get the order's periods
          this.getPeriods()
        })
        .catch(async (error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
    },
    getPeriods() {
      axios
        .get(`/v1/orders/${this.id}/periods`)
        .then((response: AxiosResponse) => {
          // We reset the field
          this.periods = []
          // We fill the periods with the order's periods
          for (const period of response.data) {
            this.periods.push(
              {
                id: period.id,
                started_at: {
                  dateObject: new Date(period.started_at),
                  dateString: period.started_at.slice(0, 10),
                  timeString: period.started_at.slice(11, 16)
                },
                finished_at: {
                  dateObject: new Date(period.finished_at),
                  dateString: period.finished_at.slice(0, 10),
                  timeString: period.finished_at.slice(11, 16)
                }
              }
            )
          }
        })
        .catch(async (error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
    },
    /**
     * Add a new period to periods
     */
    addPeriod()
    {
      let period =
      {
        id: 0,
        started_at: {dateObject: new Date(), dateString: "", timeString: ""},
        finished_at: {dateObject: new Date(), dateString: "", timeString: ""}
      } as Period

      // We get the HH:mm:ss from the startedAt previous period so that they can be used again later
      const nowStartedAtHours = new Date(this.periods[this.periods.length - 1].started_at.dateObject as Date)

      // We set the startedAt date of the new period one day after the finishedAt date of the previous period
      // ONLY if startedAt HH:mm:ss are lower than finishedAt HH:mm:ss
      //   for instance 2024-09-06 09:00:00 got its time lower than 2024-09-06 18:00:00, it will generate new period to 2024-09-07 09:00:00 and 2024-09-07 18:00:00
      //   on the other case like 2024-09-06 22:00:00 and 2024-09-07 06:00:00, it will generate new period to 2024-09-07 22:00:00 and 2024-09-08 06:00:00
      const nowStartedAtDate = new Date(this.periods[this.periods.length - 1].finished_at.dateObject as Date)
      if (this.periods[this.periods.length - 1].started_at.timeString <= this.periods[this.periods.length - 1].finished_at.timeString) {
        nowStartedAtDate.setDate(nowStartedAtDate.getDate() + 1)
      }

      // We count the time difference between the startedAt and finishedAt dates of the previous period
      let lastPeriodStartedAtDate = this.periods[this.periods.length - 1].started_at.dateObject?.getTime() ?? 0
      let lastPeriodFinishedAtDate = this.periods[this.periods.length - 1].finished_at.dateObject?.getTime() ?? 0
      let timeDifference = lastPeriodFinishedAtDate - lastPeriodStartedAtDate;

      // We define the HH:mm:ss for the startedAt date of the new period so that it corresponds to the same HH:mm:ss as the previous period
      nowStartedAtDate.setHours(nowStartedAtHours.getHours(), nowStartedAtHours.getMinutes(), nowStartedAtHours.getSeconds())
      period.started_at.dateObject = nowStartedAtDate
      // We set the finishedAt date by adding the time difference previously found
      const nowFinishedAt = new Date(nowStartedAtDate.getTime() + timeDifference)
      period.finished_at.dateObject = nowFinishedAt

      // We increment the period list inputs
      this.periods.push(
        period
      )
    },
    /**
     * Send the newly created and updated Periods to the API
     *
     */
    savePeriods(): void
    {
      let createPeriodsBody = []
      for (const period of this.periods) {
        // We store only the new periods
        if(period.id === 0) {
          createPeriodsBody.push(
            {
              started_at: `${period.started_at.dateString} ${period.started_at.timeString}:00`,
              finished_at: `${period.finished_at.dateString} ${period.finished_at.timeString}:00`,
            }
          )
        }
      }
      if (createPeriodsBody.length > 0) {
        // We create the new periods
        axios
        .post(`/v1/orders/${this.id}/periods`, createPeriodsBody)
        .then(() => {
          EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.order_successfully_updated')})
          this.$emit(
            'orderUpdated',
            {
              'updated': true,
              'coefficient': this.coefficient,
              'hourRate': this.hourRate,
              'reason': this.reason,
              'proof': this.proof
            }
          )
          this.getPeriods()
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error.response})
        })
      }
      // We get the periods that were locally updated
      let updatedPeriods = this.findUpdatedPeriods()
      if (updatedPeriods.length > 0){
        // We prepare the requests in an array
        let updatePromises = []
        for (const period of updatedPeriods) {
          let updatePeriodBody = {
            started_at: `${period.started_at.dateString} ${period.started_at.timeString}:00`,
            finished_at: `${period.finished_at.dateString} ${period.finished_at.timeString}:00`,
          }
          // if "permanent" contract_type, generate finished_at to one year after
          if (this.contractType === 'permanent') {
            // We get the startDate as base and add to it 1 year
            let nextYear = new Date(period.started_at.dateString)
            nextYear.setFullYear(nextYear.getFullYear() + 1)
            const endYear = nextYear.toISOString().split('T')[0]
            updatePeriodBody.finished_at = `${endYear} ${period.started_at.timeString}:00`
          }
          updatePromises.push(axios.put(`/v1/orders/${this.id}/periods/${period.id}`, updatePeriodBody))
        }
        Promise.all(updatePromises).then(() => {
          EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.order_successfully_updated')})
          this.getPeriods()
          this.$emit(
            'orderUpdated',
            {
              'updated': true,
              'coefficient': this.coefficient,
              'hourRate': this.hourRate,
              'reason': this.reason,
              'proof': this.proof
            }
          )
        }).catch((error: AxiosError)=> {
          let snackbarBody = {axiosError: error} as {[key:string]: any};
          if(error.response?.data[0].includes("already in contract on period")) {
            snackbarBody = {message: this.$vuetify.lang.t('$vuetify.worker_already_delegated'), color: 'error'}
          }
          this.getPeriods()
          EventBus.$emit('snackbar',snackbarBody)
      });
      }
    },
    /**
     * Remove a period from periods
     * @param id the id of the order_period to remove
     * @param index the index of the period in the periods array
     *
     */
    removePeriod(periodId: number, periodIndex: number, forceDelete: boolean|null = null): void
    {
      // We launch a request only if the period has an id > 0 (it's registered in DB)
      if(periodId !== 0) {
        let forceDeleteUrl = ''
        if (forceDelete) {
          forceDeleteUrl = '?force=true'
        }
        axios.delete(`/v1/orders/${this.id}/periods/${periodId}${forceDeleteUrl}`)
        .then(() => {
          this.closeDeleteModal(periodIndex)
          this.getPeriods()
          EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.order_period_successfully_deleted')})
          this.$emit(
            'orderUpdated',
            {
              'updated': true,
              'coefficient': this.coefficient,
              'hourRate': this.hourRate,
              'reason': this.reason,
              'proof': this.proof
            }
          )
        }).catch((error: AxiosError) => {
          if (error.response?.data === "Order period already have delegated worker") {
            this.deleteText = this.$vuetify.lang.t('$vuetify.force_delete_text')
            this.force = true
          } else {
            this.closeDeleteModal(periodIndex)
            EventBus.$emit('snackbar',{axiosError: error})
          }
        })
      } else { // Else we just remove it from the period list
        this.closeDeleteModal(periodIndex)
        this.periods.splice(periodIndex, 1)
      }

    },
    /**
     * Search in order's periods the one that have been updated
     *
     * @returns an array of Period which have been updated
     */
    findUpdatedPeriods(): Period[]
    {
      let updatedPeriods = []
      // tag as updated already existing periods
      for (const period of this.periods) {
        if (period.id && (period.started_at?.updated || period.finished_at?.updated)) {
          updatedPeriods.push(period)
        }
      }
      return updatedPeriods
    },
    /**
     * Build the body of the order to update
     *
     * @returns the body of the order to update
     */
    buildUpdateBody(): OrderBodyType
    {
      let body = {} as OrderBodyType
      if (this.dataReference.address !== this.address) body.address = this.address
      if (this.dataReference.free_text !== this.freeText) body.free_text = this.freeText
      if (Number(this.dataReference.quantity) !== this.quantity) body.quantity = this.quantity
      if (this.dataReference.force_all_periods !== this.forceAllPeriods) body.force_all_periods = this.forceAllPeriods

      let periodErrorIndexes = []
      for (let i = 0; i < this.periods.length; i++) {
        let startingAt = `${this.periods[i].started_at.dateString} ${this.periods[i].started_at.timeString}:00`
        let finishedAt = `${this.periods[i].finished_at.dateString} ${this.periods[i].finished_at.timeString}:00`
        // If finished_at is set before starting_at and for a non permanent order
        if((Date.parse(startingAt) > Date.parse(finishedAt)) && this.contractType !== 'permanent') {
          periodErrorIndexes.push(i+1)
        }
      }
      if (periodErrorIndexes.length > 0) {
        EventBus.$emit('snackbar',{message:`${this.$vuetify.lang.t('$vuetify.period_chronology_error')}: ${periodErrorIndexes.join(", ")} `, color: "error"})

        // I break the function here because we can't have multiple snackbars on screen.
        // If I don'"t break here, the 'no modification snackbar' takes over the chronologic errors one"
        return {} as OrderBodyType
      } else if (this.findUpdatedPeriods().length !== 0 || this.periods[this.periods.length-1].id === 0) {
        // If updated periods or new periods are found
        this.canSendPeriods = true
      }

      return this.addOrderProperties(body)
    },
    /**
     * Add the order properties to the body
     *
     * @param body the body of the order to update
     * @returns the body of the order to update
     */
    addOrderProperties(body: OrderBodyType): OrderBodyType {
      const propertyMappings = [
        {
          condition: () => this.contact.id !== undefined && this.dataReference.contact_id !== this.contact.id,
          property: 'contact_id',
          value: () => this.contact.id
        },
        {
          condition: () => this.selectedUser != null && this.dataReference.user_id !== this.selectedUser,
          property: 'user_id',
          value: () => this.selectedUser
        },
        {
          condition: () => this.dataReference.source !== this.source,
          property: 'source',
          value: () => this.source
        },
        {
          condition: () => this.dataReference.schedules !== this.schedules,
          property: 'schedules',
          value: () => this.schedules
        },
        {
          condition: () => this.dataReference.working_time !== this.workingTime,
          property: 'working_time',
          value: () => this.workingTime
        },
        {
          condition: () => this.dataReference.coefficient !== this.coefficient,
          property: 'coefficient',
          value: () => Number(this.coefficient) === 0 ? null : Number(this.coefficient)
        },
        {
          condition: () => this.dataReference.task !== this.formatTask(),
          property: 'task',
          value: () => this.formatTask()
        },
        {
          condition: () => this.dataReference.hour_rate !== this.hourRate,
          property: 'hour_rate',
          value: () => Number(this.hourRate) === 0 ? null : Number(this.hourRate)
        },
        {
          condition: () => this.dataReference.complementary_information !== this.complementary_information,
          property: 'complementary_information',
          value: () => this.complementary_information
        },
        {
          condition: () => this.dataReference.reason !== this.reason,
          property: 'reason',
          value: () => this.reason
        },
        {
          condition: () => this.dataReference.proof !== this.proof,
          property: 'proof',
          value: () => this.proof
        },
        {
          condition: () => this.dataReference.collective_agreement_pay_scale !== this.collective_agreement_pay_scale,
          property: 'collective_agreement_pay_scale',
          value: () => this.collective_agreement_pay_scale
        }
      ]

      propertyMappings.forEach(({ condition, property, value }) => {
        if (condition()) {
          body[property] = value()
        }
      })

      return body
    },
    formatTask(): string | null {
      const task = this.orderTask.join("\n")
      return task.length === 0 ? null : task
    },
    /**
     * Cancel the modification of the order
     */
    cancelModification() {
      this.disabled = true
      for (let i = this.periods.length - 1; i >= 0; i--) {
        if (!this.periods[i].id) {
          this.periods.splice(i, 1)
        }
      }
      if (this.selectedUser != null && this.dataReference.user_id != null && this.selectedUser !== this.dataReference.user_id) {
        this.selectedUser = this.dataReference.user_id
    }
    },
  }
})
