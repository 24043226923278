
import DateFormatMixin from "@/mixins/DateFormatMixin.vue";
import { EventBus } from "@/plugins/eventBus";
import axios, { AxiosError, AxiosResponse } from "axios";
import Vue, { VueConstructor } from "vue";

interface Space {
  sms: boolean,
  mail: boolean
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof DateFormatMixin>>).extend({
  name: 'ReportOrderClose',
  props: {
    orderData: {
      type: Object,
      required: true
    }
  },
  mixins: [DateFormatMixin],
  data () {
        return {
            space: {} as Space,
        }
    },
  async mounted() {
    await this.getSpace()
  },
  computed: {
    headers() {
      return [
        {value: "text", sortable: false},
        {value: "value", sortable: false},
      ]
    },
    dataItems(): {text: string, value: string|number}[] {
      return [
        { text: this.$vuetify.lang.t("$vuetify.report_data.temporary_company"), value: this.orderData.space_name},
        { text: this.$vuetify.lang.t("$vuetify.report_data.user_company"), value: this.orderData.client_name},
        { text: this.$vuetify.lang.t("$vuetify.report_data.sender"), value: `${this.orderData.user_first_name} ${(this.orderData.user_last_name).toUpperCase()}`},
        { text: this.$vuetify.lang.t("$vuetify.report_data.first_period_date"), value: this.localizeDate(this.orderData.periods[0].started_at,true, false)},
        { text: this.$vuetify.lang.t("$vuetify.report_data.ratio_order_job"), value: this.ratioOrderJob()},
        { text: this.$vuetify.lang.t("$vuetify.report_data.ratio_contact_job"), value: this.ratioContactJob()},
        { text: this.$vuetify.lang.t("$vuetify.report_data.first_job_accept_date"), value: this.localizeDate(this.orderData.report.first_job_accepted_date, true, false)},
        { text: this.$vuetify.lang.t("$vuetify.report_data.completion_date"), value: this.localizeDate(this.orderData.report.completion_date, true, false)},
        { text: this.$vuetify.lang.t("$vuetify.report_data.start_date"), value: this.localizeDate(this.orderData.started_at, true, false)},
        { text: this.$vuetify.lang.t("$vuetify.report_data.worker_contacted"), value: this.orderData.report.workers_contacted},
      ]
    },
    // comment is data present on v1 and needed to finalize v3 order
    summaryItems(): {text: string, value: string|number}[] {
      return [
        { text: this.$vuetify.lang.t("$vuetify.client"), value: this.orderData.client_name},
        { text: this.$vuetify.lang.t("$vuetify.position"), value: this.orderData.position_name},
        { text: this.$vuetify.lang.t("$vuetify.number_of_people_required"), value: this.orderData.quantity},
        { text: this.$vuetify.lang.t("$vuetify.begin_date"), value: this.localizeDate(this.orderData.started_at,true,false)},
        { text: this.$vuetify.lang.t("$vuetify.end_date"), value: this.localizeDate(this.orderData.finished_at,true,false)},
        { text: this.$vuetify.lang.t("$vuetify.address"), value: this.orderData.address},
        // { text: this.$vuetify.lang.t("$vuetify.cycle"), value: ''},
        // { text: this.$vuetify.lang.t("$vuetify.weekly_duration"), value: ""},
        { text: this.$vuetify.lang.t("$vuetify.missions"), value: this.orderData.position_name},
        { text: this.$vuetify.lang.t("$vuetify.further_information"), value: this.orderData.free_text},
        // { text: this.$vuetify.lang.t("$vuetify.manager"), value: ''},
        // { text: this.$vuetify.lang.t("$vuetify.anonymous_customer"), value: ''},
        // { text: this.$vuetify.lang.t("$vuetify.sms_validation"), value: ''},
        // { text: this.$vuetify.lang.t("$vuetify.display_city_in_sms"), value: ''},
        { text: this.$vuetify.lang.t("$vuetify.sms_notification"), value: this.space.sms ? this.$vuetify.lang.t('$vuetify.ok') : ''},
        { text: this.$vuetify.lang.t("$vuetify.contact"), value: `${this.orderData.contact_first_name} ${this.orderData.contact_last_name.toUpperCase()} - ${this.orderData.contact_email} - ${this.orderData.contact_phone}`},
        { text: this.$vuetify.lang.t("$vuetify.mail_notification"), value: this.space.mail ? this.$vuetify.lang.t('$vuetify.ok') : ''},
        // { text: this.$vuetify.lang.t("$vuetify.reason_for_appeal"), value: ''},
        // { text: this.$vuetify.lang.t("$vuetify.receipt"), value: ''},
        // { text: this.$vuetify.lang.t("$vuetify.source"), value: ''},
        // { text: this.$vuetify.lang.t("$vuetify.tariff_coefficient"), value: ''},
        // { text: this.$vuetify.lang.t("$vuetify.business_manager"), value: ''}

      ]
    }
  },
  methods: {
    ratioOrderJob(): string {
      return `${Math.round((this.orderData.report.count_arrived_at/this.orderData.report.count_accepted)*100)} %`
    },
    ratioContactJob(): string {
      if (this.orderData.report.count_arrived_at !== 0 && this.orderData.report.workers_contacted!== 0) {
        return `${Math.round((this.orderData.report.count_arrived_at/this.orderData.report.workers_contacted)*100)} %`
      }
      return ''
    },
    async getSpace() {
      await axios.get(`/v1/spaces/${this.orderData.space_id}`)
            .then((response: AxiosResponse) => {
              this.space = response.data[0]
            })
            .catch((e: AxiosError) => {
                EventBus.$emit('snackbar',{axiosError: e})
            })
    }
  }
})
