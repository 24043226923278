
import Vue, { VueConstructor } from "vue"
import ListParagon, { Filter } from "@/components/ListParagon.vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"
import SendSMSDialog from "../workers/SendSMSDialog.vue"
import PermissionMixin from "@/mixins/PermissionMixin.vue"

interface AddressPosition {
  [key: string]: number
}

interface OrderInterface {
  started_at?: string,
  finished_at?: string,
  id: string,
  space_id: string, 
  position_id?: string,
  force_available_workers?: number,
  force_all_periods: boolean,
  address_position: AddressPosition,
  coefficient: number,
  hour_rate: number,
  reason: string,
  proof: string
}

interface OrderStatus {
  id: number,
  title: string,
  weight: number|null,
  step: number,
  workersCount: number|null
}

interface OrderPeriod {
  id: number,
  started_at: string,
  finished_at: string
}

interface Header {
  text: string,
  sortable: boolean,
  value: string,
  sortText?: string
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof PermissionMixin>>).extend({
  name: 'WorkerFollowUp',
  mixins: [PermissionMixin],
  props: {
    id: { // Order id
      type: Number,
      required: true
    },
    updatedOrder :{
      type: Boolean
    },
    selection: {
      type: Object,
      default: null
    },
    emitMatching: {
      type: Boolean,
    },
    isClosed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ListParagon,
    SendSMSDialog
  },
  data () {
    return {
      selectedItems: {} as Record<string, unknown>,
      selectedOrderTemplateStatusId: null as null | number, // selected order_template_status.id filter used in ListParagon
      order: {} as OrderInterface,
      orderPeriods: [] as Array<OrderPeriod>, // retrieved order's order_period
      selectedOrderPeriods: [] as Array<OrderPeriod>, // selected order_period(s)
      selectionData: {},
      loading: true,
      updateLoading: false, // loading state used on update worker btn
      actionMenu: "",
      selectedStatus: null as OrderStatus|null,
      disabled: true,
      reload: 0,
      statusArray: [] as Array<OrderStatus>,
      showSendProposal: false,
      missionProposalText: "",
      spaceName: "",
    }
  },
  async mounted() {
    await this.getOrder()
    if (this.order.space_id !== undefined) {
      this.getSpace()
    }
    this.selectionData = this.$props.selection
    this.selectedItems = this.selectionData
  },
  watch : {
    updatedOrder() {
      this.getOrder()
    },
    selection() {
      this.selectionData = this.$props.selection
    },
    selectionData() {
      if (this.emitMatching) {
        this.reload++
      }
    }
  },
  computed: {
    filters: function(): Array<Filter> {
      let result = [] as Array<Filter>
      if (this.order.address_position !== undefined && this.order.address_position !== null) {
        result.push({
          label: 'address_distance',
          type: 'number',
          value: `${this.order.address_position.longitude};${this.order.address_position.latitude}`,
        } as Filter)
      } else {
        result.push({
          label: 'address_distance',
          type: 'number',
          value: ``,
          disable: true
        } as Filter)
      }
      return result
    },
    headers: function(): Array<Header> {
      let result = [
        {
          text: this.$vuetify.lang.t("$vuetify.last_name"),
          sortable: false,
          value: 'last_name'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.first_name"),
          sortable: false,
          value: 'first_name'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.email"),
          sortable: false,
          value: 'email'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.step"),
          sortable: false,
          value: 'step'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.mobile_phone_number"),
          sortable: false,
          value: 'mobile_phone_number'
        },
        {
          text: this.$vuetify.lang.t("$vuetify.address_distance"),
          sortable: true,
          value:'address_distance'
        }
      ]
      return result
    },
    /**
     * Base URL used on ListParagon in order to retrieve workers
     */
    url(): string {
      let url = `/v1/workers?fields=last_name,first_name,email,mobile_phone_number,status&order=true:${this.id}`
      if (this.selectedOrderTemplateStatusId) {
        url += `:${this.selectedOrderTemplateStatusId}`
      }
      // Distance filter
      if (this.order.address_position !== null && this.order.address_position !== undefined) {
        url += `&address_distance=${this.order.address_position.longitude};${this.order.address_position.latitude}`
      }
      return url
    },
    /**
     * Check if periods selection is active or not on UI
     */
    canSelectPeriods(): boolean {
      return this.orderPeriods.length > 0 && this.selectedStatus?.weight != null
    },
    /**
     * Return icon used on select all periods v-select
     */
    selectAllPeriodsIcon(): string {
      if (this.selectedOrderPeriods.length == 0) {
        return 'mdi-checkbox-blank-outline'
      } else if (this.selectedOrderPeriods.length == this.orderPeriods.length) {
        return 'mdi-close-box'
      } else {
        return 'mdi-minus-box'
      }
    }
  },
  methods: {
    async getOrder() {
      // get order
      await axios
      .get(`/v1/orders/${this.id}`)
      .then((response: AxiosResponse) => {
        this.statusArray  = []
        // set order data  
        this.order = response.data
        // By default, we get the selected workers
        for (let status of response.data.template.status as Array<OrderStatus>) {
          this.statusArray.push(
            {
              id: status.id,
              title: this.$vuetify.lang.t(`$vuetify.order_template_status.${status.title}`),
              step: status.step,
              weight: status.weight,
              workersCount: null
            }
          )
        }
        this.getCountOrderStatusWorkers(this.statusArray)
        this.loading = false
      }).catch((error: AxiosError) => {
        EventBus.$emit('snackbar', { axiosError: error })
          this.$router.push({ path: '/orders' })
      })
      // get order periods
      axios.get(`/v1/orders/${this.id}/periods`)
      .then((response: AxiosResponse) => {
        this.orderPeriods = response.data
        this.selectedOrderPeriods = response.data // select all periods by default
      }).catch((error: AxiosError) => {
        EventBus.$emit('snackbar', { axiosError: error })
            this.$router.push({ path: '/orders' })
      })
    },
    async getSpace() {
      // get space
      await axios
      .get(`/v1/spaces/${this.order.space_id}`)
      .then((response: AxiosResponse) => {
          // set order data  
          this.spaceName = response.data[0].name
          
          this.missionProposalText = `${this.spaceName} ${this.$vuetify.lang.t('$vuetify.mission_proposal_sms')} {{worker.job_proposition.${this.id}}}`
        }).catch((error: AxiosError) => {
          EventBus.$emit('snackbar', { axiosError: error })
            this.$router.push({ path: '/orders' })
        }
      )
    },
    reloadData() {
      // force refresh paragon
      this.reload++
    },
    arraySelectedItems(selectParagon: Record<string, unknown>) {
      this.selectedItems = selectParagon
      if (this.emitMatching === false) {
        this.$emit('selectedWorkers', this.selectedItems)
      }
    },
    /**
     * Submit modifications for order's selected workers
     */
    updateOrderWorkers() {
      let body = {} as {
        [key: string] : {
          status: number|undefined,
          periods?: Array<number>,
          coefficient?: number,
          hour_rate?: number,
          reason?: string,
          proof?: string
        }
      }

      for (const item of Object.keys(this.selectedItems)) {
        // add selected status
        body[item] = {
          status: this.selectedStatus?.id
        }
        // add periods if any
        if (this.canSelectPeriods) {
          // map return an array of order_period.id
          body[item].periods = this.selectedOrderPeriods.map((eachOrderPeriod: OrderPeriod) => { return eachOrderPeriod.id })
          if (this.order.coefficient) {
            body[item].coefficient = Number(this.order.coefficient)
          }
          if (this.order.hour_rate) {
            body[item].hour_rate = Number(this.order.hour_rate)
          }
          if (this.order.reason) {
            body[item].reason = this.order.reason
          }
          if (this.order.proof) {
            body[item].proof = this.order.proof
          }
        }
      }

      this.updateLoading = true
      axios.put(`/v1/orders/${this.order.id}/workers`, body)
      .then(() => {
        // success snackbar
        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.update_success')})
        this.getCountOrderStatusWorkers(this.statusArray)
        this.selectedStatus = null // reset selected status
        this.selectedOrderPeriods = this.orderPeriods // reset selected periods to all available
        this.selectionData = this.selectedItems
        this.reloadData()
      }).catch((error: AxiosError) => {
        this.handleErrors(error)
      }).finally(() => {
        this.updateLoading = false
      })
    },
    getCountOrderStatusWorkers(statusArray: Array<OrderStatus>) {
      //for each order status, request API on /workers?selectAll=true to count the returned ids
      for (const status of statusArray) {
        axios
        .get(`/v1/workers?order=true:${this.id}:${status.id}&selectAll=true`)
        .then((response: AxiosResponse) => {
          status.workersCount = response.data.length
          }).catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{ axiosError: error })
            this.$router.push({ path: '/orders' })
          })
      }
    },
    selectAllPeriods() {
      if (this.selectedOrderPeriods.length == this.orderPeriods.length) {
        this.selectedOrderPeriods = []
      } else {
        this.selectedOrderPeriods = this.orderPeriods
      }
    },
    /**
     * Handle special cases error + general one
     */
    handleErrors(error: AxiosError) {
      // try to qualify special cases error
      let specialCase = {
        message: "",
        color: "error"
      }
      // Start - check if one or multiple period have quantity exceeded error
      // get an array of period_id error
      let matches = [...error.response?.data.matchAll(/Order quantity exceeded on period_id=(\d+)/g) || []]
      if (matches.length > 0) {
        // get period_id in error
        let errorPeriodIds = matches.map((match) => { return Number(match[1]) }) // match[1] is captured group
        // get periods in error
        let errorPeriods = this.selectedOrderPeriods.filter((eachPeriod) => errorPeriodIds.includes(eachPeriod.id))
        // add error message for each period
        errorPeriods.forEach((period) => {
          specialCase.message += `${this.$vuetify.lang.t('$vuetify.api_errors.Order quantity exceeded')} ${this.$vuetify.lang.t('$vuetify.on_period')} ${this.getOrderPeriodTitle(period)}. `
        })
      }
      // End - check if one or multiple period have quantity exceeded error

      // Start - check if one worker is already in contract
      matches = [...error.response?.data.matchAll(/worker_id=(\d+) already in contract on period_id=(\d+)/g) || []]
      matches.forEach((match) => {
        let errorWorkerId = Number(match[1])
        let errorPeriodId = Number(match[2])

        let errorWorker = (this.$refs.list as InstanceType<typeof ListParagon>).$data.items.find((eachWorker: {id: number}) => eachWorker.id === errorWorkerId)
        let errorPeriod = this.selectedOrderPeriods.find((eachPeriod: OrderPeriod) => eachPeriod.id === errorPeriodId)
        
        if (errorWorker) {
          specialCase.message += `${errorWorker.last_name} ${errorWorker.first_name} ${this.$vuetify.lang.t('$vuetify.already_in_contract')} ${this.$vuetify.lang.t('$vuetify.on_period')} ${this.getOrderPeriodTitle(errorPeriod as OrderPeriod)}.`
        } else {
          specialCase.message += `${this.$vuetify.lang.t('$vuetify.worker_already_in_contract')} ${this.$vuetify.lang.t('$vuetify.on_period')} ${this.getOrderPeriodTitle(errorPeriod as OrderPeriod)}.`
        }
      })
      // End - check if one or multiple period have quantity exceeded error
      // emit error
      EventBus.$emit('snackbar', specialCase.message != "" ? specialCase : { axiosError: error })
    },
    /**
     * Localise step title used on chips
     */
    getStepTitle(order_worker: {status: number, period_id: number|null}) {
      let title = ""
      let status = this.statusArray.find((eachStatus) => { return eachStatus.id == order_worker.status })
      if (status) {
        title += status.title
      } 
      let period = this.orderPeriods.find((eachPeriod) => { return eachPeriod.id == order_worker.period_id })
      if (period) {
        title += ` ${this.getOrderPeriodTitle(period)}` 
      }
      return title
    },
    /**
     * Generate label used as title on order period selection and error messages
     */
     getOrderPeriodTitle(period: OrderPeriod) {
      // retrieve order period index
      let index = this.orderPeriods.findIndex((eachPeriod: OrderPeriod) => eachPeriod.id == period.id) + 1 // index start from 0
      // generate dates
      let started_at = new Date(period.started_at)
      let finished_at = new Date(period.finished_at)
      if (this.$vuetify.lang.current === 'fr') {
        return `P${index} du ${('0' + started_at.getDate()).slice(-2)}/${('0' + (started_at.getMonth() + 1)).slice(-2)} au ${('0'+finished_at.getDate()).slice(-2)}/${('0'+(finished_at.getMonth()+1)).slice(-2)} de ${('0'+started_at.getHours()).slice(-2)}h${('0'+started_at.getMinutes()).slice(-2)} à ${('0'+finished_at.getHours()).slice(-2)}h${('0'+finished_at.getMinutes()).slice(-2)}`
      } else {
        return `P${index}: ${('0' + (started_at.getMonth() + 1)).slice(-2)}/${('0' + started_at.getDate()).slice(-2)} to ${('0' + (finished_at.getMonth() + 1)).slice(-2)}/${('0'+finished_at.getDate()).slice(-2)} from ${('0'+started_at.getHours()).slice(-2)}h${('0'+started_at.getMinutes()).slice(-2)} to ${('0'+finished_at.getHours()).slice(-2)}h${('0'+finished_at.getMinutes()).slice(-2)}`
      }
    }
  }
})
